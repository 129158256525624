<template>
  <div>
    <dx-util-popup
      ref="popupShipmentItemsRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="true"
      content-template="popup-content"
      :title="`${batchName}: Shipment Batch Items`"
      @showing="onShowingShipmentItemsPopup"
      @hiding="onHidingShipmentItemsPopup"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <shipment-items v-if="showShipmentItems" :ship-batch-id="batchId" />
      </template>
    </dx-util-popup>
    <div class="row">
      <div class="col-md-12">
        <dx-data-grid
          id="trackItemsGrid"
          ref="mainGrid"
          :data-source="grid.dataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          @cell-dbl-click="onCellDblClick"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
        >
          <dx-load-panel :enable="true" />
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1 w-25">
                <dx-util-text-box
                  v-model.trim="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Acc/Suite No"
                  @enter-key="getSearchData"
                />
              </div>
              <div class="d-flex row ml-2 mr-1 w-35">
                <dx-util-text-box
                  v-model="grid.currentFilter"
                  :show-clear-button="true"
                  placeholder="Search by any code..."
                  @enter-key="getSearchData"
                />
                <dx-util-button
                  type="default"
                  text="Check"
                  width="100px"
                  class="ml-1"
                  @click="getSearchDataByClick"
                  />
              </div>
              <div class="mr-1 w-35">
                <dx-util-check-box
                    v-model="isTitle"
                    text="Title"
                    hint="Check this if you search by product title. Please note that searching by title may take longer time. And quantity snapshots are not calculated when you search by title."
                />
              </div>
              <div class="text-right ml-2">
                <p-icon id="inventorySearchTab" name="bi-question-circle"
                  color="warning" class="pt-half cursor-pointer"
                  size="24px"
                />
                <dx-util-popover :width="300" :visible="false"
                  target="#inventorySearchTab" position="right"
                  show-event="dxhoverstart"
                  hide-event="dxhoverend"
                >
                  This page helps you to get a snapshot of items. You can search items by UPC, ASIN, MSKU, FNSKU, Tracking, Order Number or Title.
                </dx-util-popover>
              </div>
            </div>
          </template>
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <b-img-lazy :rounded="true" width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column :visible="false" data-field="batchId" :sort-index="sortIndex1" sort-order="asc" />
          <dx-column
            v-if="$can('read', 'resource_customer_account_no')"
            data-field="accountNo"
          />
          <dx-column
            v-if="$can('read', 'resource_customer_account_no')"
            data-field="suiteNo"
          />
          <dx-column data-field="batchName" cell-template="clientBatchNameTemplate" />
          <dx-column data-field="title" cell-template="productTitleTemplate" />
          <dx-column data-field="asin" caption="ASIN" />
          <dx-column data-field="upc" caption="UPC" />
          <dx-column data-field="fnsku" caption="FNSKU" />
          <dx-column data-field="msku" caption="MSKU" />
          <dx-column data-field="orderNumber" />
          <dx-column data-field="tracking" />
          <dx-column data-field="totalQuantity" class="text-center" />
          <dx-column data-field="inTransit" />
          <dx-column data-field="inInventory" :sort-index="sortIndex3" sort-order="desc" />
          <!-- <dx-column data-field="inInventory" caption="damaged" :sort-index="sortIndex3" sort-order="desc" /> -->
          <dx-column data-field="problem" />
          <dx-column data-field="shipped" :sort-index="sortIndex2" sort-order="desc" />
          <dx-column data-field="uploadedAt" data-type="date" alignment="right" />
          <template #clientBatchNameTemplate="{ data }">
            <a class="font-weight-bold d-block text-nowrap" @click="onBatchNameLinkClick(data)">
              <span class="text-capitalize badge badge-light-warning badge-pill">
                {{ data.value }}
                <feather-icon class="ml-1" icon="ExternalLinkIcon" size="18" />
              </span>
            </a>
            </template>
          <template #productTitleTemplate="{ data }">
             <div class="product-title">
              {{ data.value }}
           </div>
          </template>
          <dx-master-detail :enabled="true" template="masterDetailTemplate" />
          <template #masterDetailTemplate="{ data: detail }">
            <track-item-details :detail-data="detail" />
          </template>
        </dx-data-grid>
      </div>
      <div class="col-md-12 px-0">
        <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
      </div>
      <div class="col-md-12 px-0">
        <dx-util-popup
          ref="catalogItemPopupRef"
          :show-close-button="true"
          :drag-enabled="true"
          :close-on-outside-click="true"
          :show-title="true"
        >
          <dx-util-position at="center" my="center" />
        </dx-util-popup>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import CoolLightBox from 'vue-cool-lightbox'
import reportService from '@/http/requests/reports/reportService'
import ShipmentItems from '~/views/apps/ship/shipment-items/ShipmentItems'
import TrackItemDetails from './TrackItemDetails.vue'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'track-item-details': TrackItemDetails,
    'shipment-items': ShipmentItems,
    'light-box': CoolLightBox,
  },
  mixins: [GridBase],
  data() {
    return {
      enterPressed: false,
      isResult: false,
      isTitle: false,
      batchName: '',
      batchId: '',
      accountNo: '',
      resultData: [],
      grid: {
        options: {
          allMode: 'allPages',
          checkBoxesMode: 'always',
        },
        dataSource: [],
        currentFilter: '',
      },
      defaultImg: defaultImageUrl,
      images: [],
      index: null,
      sortIndex1: 1,
      sortIndex2: 2,
      sortIndex3: 3,
      showShipmentItems: false,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  methods: {
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onShowingShipmentItemsPopup() {
      this.showShipmentItems = true
    },
    onBatchNameLinkClick(e) {
      this.batchId = e.data.batchId
      this.batchName = e.value
      const popup = this.$refs.popupShipmentItemsRef.instance
      popup.show()
    },
    onHidingShipmentItemsPopup() {
      this.showShipmentItems = false
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
    },
    getSearchData(e) {
      if (!this.grid.currentFilter) {
        return
      }
      if (e.event.keyCode === 13) {
        const filter = {
          q: this.grid.currentFilter,
          accountNo: this.accountNo,
          title: this.isTitle,
        }
        reportService.fetchItemsByAnyCode(filter).then(result => {
          const { data } = result
          this.grid.dataSource = data
        })
      }
    },
    getSearchDataByClick() {
      const filter = {
        q: this.grid.currentFilter,
        accountNo: this.accountNo,
        title: this.isTitle,
      }
      reportService.fetchItemsByAnyCode(filter).then(result => {
        const { data } = result
        this.grid.dataSource = data
      })
    },
    customizeColumns(columns) {
      columns.forEach(column => {
        // eslint-disable-next-line no-param-reassign
        column.cssClass = 'text-center dx-column-vertical-middle'
      })
    },
  },
}
</script>

<style scoped>

.product-title {
  min-width: 300px;
  max-width: 400px;
  height: 100%;
  overflow-wrap:break-word;
  white-space:normal;
  text-align: left;
}
</style>
