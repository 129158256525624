import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {

  fetchAllResources() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.resourceController.getRosources())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  getCompanyGrantableResource(roleId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.resourceController.getCompanyGrantableResource(roleId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  checkRouterResourceMapping(routerData) {
    const routerDataResource = []

    routerData.forEach(element => {
      if (element && element.resource && !element.action) {
        routerDataResource.push({ resource: element.resource })
      }
      if (element && element.resource && element.action) {
        routerDataResource.push({ resource: element.resource, action: element.action })
      }
    })
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.system.resourceController.checkRouterResourceMapping(), routerDataResource)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // create prep material purchase
  async createResource(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.resourceController.createRosource(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  // getTemplateRoleResourceActionByCompanyTypeAndResourceId
  async getTemplateRoleResourceActionByCompanyTypeAndResourceId(companyType, resourceId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleResourceActionController.getTemplateRoleResourceActionByCompanyTypeAndResourceId(companyType, resourceId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async grantResourceByCompanyType(resourceId, companyType, roleResourceAction) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.roleResourceActionController.grantResourceByCompanyType(resourceId, companyType), roleResourceAction)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // update prep material purchase
  async updateResource(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.system.resourceController.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // delete prep material purchase
  async deleteResource(roleId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.system.resourceController.delete(roleId))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
