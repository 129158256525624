import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {

  fetchItemsByAnyCode(filter) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.reports.Controller.findItemsByAnyCode(), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  findTrackingItemsByBatch(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.reports.Controller.findTrackingItemsByBatch(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
