<template>
  <main class="container-fluid">
    <section class="row">
      <div class="col-12 px-0">
        <div class="card card-psh p-half">
          <dx-util-tab-panel :height="setHeight" :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true">
            <dx-util-panel-item v-if="accountNo" title="Item Delivery Status" icon="bi bi-box">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                    <div class="pt-0 px-half">
                      <summary-first-row-cards />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="accountNo" title="Multiple Items Delivery Status" icon="bi bi-boxes">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                    <div class="pt-0 px-half">
                      <multiple-delivery-check />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasTrackItemStatus" title="Item Snapshot" icon="bi bi-code">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                    <div class="pt-0 px-half">
                      <tab-search-track-items />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasInventorySnapshot" title="Inventory Snapshot" icon="bi bi-list-task">
              <template #default>
                <dx-util-scroll-view width="100%" height="100%">
                    <div class="pt-0 px-half">
                      <tab-inventory-items />
                  </div>
                </dx-util-scroll-view>
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
          <div v-if="!accountNo">
            Your account setup has not been completed yet! You can finish your account registration at Account > My Account.
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
/* eslint-disable no-unused-vars */

import userResourceService from '@/http/requests/system/userResourceService'
import { getUserData } from '@/auth/utils'
import TabSearchTrackItems from './components/TabSearchTrackItems.vue'
import TabInventoryItems from './components/TabInventoryItems.vue'
import SummaryFirstRowCards from './components/SummaryFirstRowCards.vue'
import MultipleDeliveryCheck from './components/MultipleDeliveryCheck.vue'

const mode = process.env.VUE_APP_APPLICATION_MODE

export default {
  components: {
    TabSearchTrackItems,
    TabInventoryItems,
    SummaryFirstRowCards,
    MultipleDeliveryCheck,
  },
  data() {
    return {
      accountNo: '',
      tenantId: '',
      userTokenData: '',
      payload: '',
    }
  },
  computed: {
    hasTrackItem() {
      return this.$can('read', 'resource_summary_track_item')
    },
    hasTrackItemStatus() {
      return this.$can('read', 'resource_summary_track_item_status')
    },
    hasInventorySnapshot() {
      return this.$can('read', 'resource_summary_inventory_snapshot')
    },
    setHeight() {
      return window.innerHeight / 1.2
    },
  },
  created() {
    const userData = getUserData()
    this.accountNo = userData.accountNo
    this.tenantId = userData.tenantId
    if (mode === 'development') {
      const routerData = this.$router.options.routes.map(result => result.meta)
      userResourceService.checkRouterResourceMapping(routerData)
    }
  },
}
</script>

<style lang="scss">
#itemTrackingTabs {
  .nav-pills {
    margin-top: 0.571rem;
    .nav-item {
      margin-right: 0.286rem;
      .nav-link {
        padding-left: 0.571rem;
        padding-right: 0.571rem;
        background-color: rgba(41, 128, 185, 0.5);
        &.active{
          background-color: #2980b9;
        }
      }
    }
  }
}
.dx-tab {
  border-radius: 3px 3px 0 0;
}
</style>
