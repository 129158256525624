<template>
  <div class="card mb-0 p-0">
    <div class="card-header bg-primary darken-2 px-1 py-half">
      <div class="d-flex w-100 align-items-center justify-content-between">
        <div class="mr-auto p-0 align-self-center">
          <h4 class="font-weight-bold mb-0  text-white">
            Charges
          </h4>
        </div>
        <div v-if="hasPermission || isIndividualTenant" class="p-0 mr-1 align-self-center">
          <dx-util-check-box
            v-model="updateDefaultCharges"
            :read-only="readOnly"
            text="Update Default Charges"
            class=" text-white"
            @value-changed="onValueChangeUpdateDefaultCharges"
          />
        </div>
        <div class="p-0 mr-1 align-self-center">
          <span class="badge badge-secondary">{{ charges.length }} Charge added</span>
        </div>
        <div v-if="hasPermission || isIndividualTenant" class="p-0 align-self-center">
          <b-button variant="success" size="sm" :disabled="readOnly" @click="onClickAddCharge()">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Charge</span>
          </b-button>
        </div>
      </div>
    </div>
    <div id="charge-content" class="card-body border p-0 mb-0 table-responsive-md">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" class="text-capitalize text-yellow font-weight-bold col-2">
              Item
            </th>
            <th scope="col" class="text-capitalize text-yellow font-weight-bold col-2">
              Charge Type
            </th>
            <th scope="col" class="text-capitalize text-yellow font-weight-bold col-4">
              Charge Name
            </th>
            <th scope="col" class="text-capitalize text-yellow font-weight-bold">
              Rate
            </th>
            <th scope="col" class="text-capitalize text-yellow font-weight-bold">
              Count
            </th>
            <th scope="col" class="text-capitalize text-yellow font-weight-bold" />
          </tr>
        </thead>
        <tbody>
          <tr v-if="charges.length === 0">
            <td scope="row" colspan="6" class="text-center h4 py-2">
              Add Charges
            </td>
          </tr>
          <tr v-for="(item, index) in charges" :key="item.rowId">
            <td scope="row" class="col-2">
              <dx-util-select-box
                  v-model="item.itemId"
                  :data-source="productItems"
                  :drop-down-options="selectBoxAttributes"
                  :disabled="readOnly"
                  display-expr="text"
                  value-expr="id"
                />
            </td>
            <td class="col-2">
              <dx-util-select-box
                v-model="item.chargeType"
                :data-source="chargeTypeEnums"
                :drop-down-options="{width: '180px'}"
                :read-only="readOnly"
                display-expr="text"
                value-expr="key"
                @value-changed="onValueChangeChargeType($event, item.rowId)"
              />
            </td>
            <td v-if="item.isPrepMaterial" class="col-4">
              <dx-util-autocomplete
                v-model="item.costItem"
                :data-source="costItems"
                :min-search-length="3"
                :max-item-count="30"
                :show-clear-button="true"
                :read-only="readOnly"
                value-expr="title"
                search-expr="title"
                placeholder="Prep Material Name"
                @selection-changed="onSelectionChangedCostData($event, item.rowId)"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Prep Material is required" />
                </dx-util-validator>
              </dx-util-autocomplete>
            </td>
            <td v-else class="col-4">
              <dx-util-text-box
                v-model="item.notes"
                :read-only="readOnly"
                placeholder="Charge Note"
              />
            </td>
            <td>
              <dx-util-number-box
                v-model="item.rate"
                :element-attr="inputClass"
                :read-only="item.isPrepMaterial || readOnly"
                :hover-state-enabled="false"
              />
            </td>
            <td>
              <dx-util-number-box
                v-model="item.count"
                :element-attr="inputClass"
                :read-only="readOnly"
                :min="1"
                :max="maxInt"
                :show-spin-buttons="false"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Count is required" />
                </dx-util-validator>
              </dx-util-number-box>
            </td>
            <td>
              <b-button
              v-if="hasPermission || isIndividualTenant"
                variant="danger"
                class="btn-icon"
                :disabled="readOnly"
                @click="onClickRemoveCharge(index)"
              >
                <feather-icon icon="XIcon" />
              </b-button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  ref, computed, onMounted, watch, onBeforeUnmount,
} from '@vue/composition-api'
import { emitter } from '@/libs/bus'
import find from 'lodash/find'
import filter from 'lodash/filter'
import Pager from '@core/dev-extreme/utils/pager'
import { Notify } from '@robustshell/utils/index'
import prepMaterialInventoryService from '@/http/requests/prep-material/prepMaterialInventoryService'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import { v4 as uuidv4 } from 'uuid'
import useCurrentUser from '@/libs/app/current-user'
import chargeTypeEnum, { getSelectedListChargeTypeEnum } from './charge-type.enum'

export default {
  name: 'Charges',
  props: {
    itemId: {
      type: Number,
      required: false,
      default: null,
    },
    readOnly: {
      type: Boolean,
      require: false,
      default: () => false,
    },
    products: {
      type: Array,
      require: false,
      default: () => [],
    },
    chargeItems: {
      type: Array,
      require: false,
      default: () => [],
    },
    loadWithItemId: {
      type: Boolean,
      require: false,
      default: () => false,
    },
    /**
     * @type {string} - combined, product, box
     */
    itemType: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  setup(props, context) {
    const { isIndividualTenant } = useCurrentUser()
    const charges = ref([])
    const { itemId, products, chargeItems } = props
    const chargeTypeEnums = ref([...getSelectedListChargeTypeEnum(
      chargeTypeEnum.ITEM_PREP_CHARGE,
      chargeTypeEnum.PREP_MATERIAL,
      chargeTypeEnum.SERVICE,
      chargeTypeEnum.STORAGE,
      chargeTypeEnum.SHIPPING,
      chargeTypeEnum.OTHER,
    )])
    const inputClass = ref({
      class: 'w-100',
    })
    const selectBoxAttributes = ref({
      minWidth: '600px',
    })
    const updateDefaultCharges = ref(true)
    const costItems = ref([])
    const chargesCount = computed(() => charges.value.length)
    const maxInt = computed(() => Number.MAX_SAFE_INTEGER)
    const productItems = computed(() => {
      const productArr = []
      if (!props.loadWithItemId) {
        productArr.push({ id: itemId, text: 'Shipment Box' })
        if (products.length > 0) {
          products.forEach(product => {
            productArr.push({ id: product.id, text: product.text.replace('null-', '') })
          })
          return productArr
        }
        return productArr
      }
      if (props.loadWithItemId && props.itemType === 'box') {
        productArr.push({ id: itemId, text: 'Shipment Box' })
      }
      if (props.loadWithItemId && props.itemType === 'product') {
        products.forEach(product => {
          productArr.push({ id: product.id, text: product.text.replace('null-', '') })
        })
      }
      return productArr
    })

    // onBeforeUnmount(() => {
    //   context.emit('on-emit-reset-cost-items')
    // })
    watch(() => props.chargeItems, () => {
      props.chargeItems.forEach(item => {
        charges.value.push({
          rowId: uuidv4(),
          id: item.id,
          itemId: item.itemId,
          isPrepMaterial: item.chargeType === chargeTypeEnum.PREP_MATERIAL.key,
          costId: item.costId,
          costItem: item.title,
          chargeType: item.chargeType,
          rate: item.rate,
          count: item.count,
          notes: item.notes,
        })
      })
    }, { immediate: true, deep: true })

    onMounted(() => {
      const pager = new Pager({})
      prepMaterialInventoryService.fetchAll(pager.staticPageable)
        .then(response => {
          costItems.value.length = 0
          response.data.content.forEach(item => {
            costItems.value.push({
              id: item.id,
              title: item.title,
              salePrice: item.salePrice,
            })
          })
        })
    })
    function addNewCharge() {
      charges.value.push({
        rowId: uuidv4(),
        id: null,
        itemId: itemId,
        isPrepMaterial: true,
        costId: 0,
        costItem: '',
        chargeType: chargeTypeEnum.PREP_MATERIAL.key,
        rate: 0,
        count: 1,
        notes: '',
      })
    }
    function checkAddNewCharge() {
      if (chargesCount.value > 0) {
        const items = filter(charges.value, item => item.count === 0 || item.rate === 0)
        return items.length === 0
      }
      return false
    }
    function onClickRemoveCharge(index) {
      charges.value.splice(index, 1)
    }
    function onClickAddCharge() {
      if (chargesCount.value === 0) {
        addNewCharge()
      } else if (checkAddNewCharge()) {
        addNewCharge()
      } else {
        Notify.warning('Please enter valid charge value.')
      }
    }
    function onSelectionChangedCostData(e, rowId) {
      const costItem = e.selectedItem
      const charge = find(charges.value, item => item.rowId === rowId)
      if (costItem) {
        Object.assign(charge, {
          costId: costItem.id,
          costItem: costItem.title,
          rate: costItem.salePrice,
          notes: '',
        })
      } else {
        charge.chargeType = chargeTypeEnum.PREP_MATERIAL.key
        this.setDefaultChargeData(charge)
      }
    }
    function setDefaultChargeData(item) {
      const charge = {
        ...item,
        id: null,
        costId: 0,
        costItem: '',
        rate: 0,
        count: 1,
        notes: '',
      }
      return charge
    }
    function getChargeItems(id) {
      itemChargeService
        .fetchAllByItemId(id)
        .then(result => {
          const { data } = result
          if (data.length > 0) {
            data.forEach(item => {
              charges.value.push({
                rowId: uuidv4(),
                id: item.id,
                itemId: item.itemId,
                isPrepMaterial: item.chargeType === chargeTypeEnum.PREP_MATERIAL.key,
                costId: item.costId,
                costItem: item.title,
                chargeType: item.chargeType,
                rate: item.rate,
                count: item.count,
                notes: item.notes,
              })
            })
          }
        })
    }
    watch(() => props.itemId, () => {
      if (props.loadWithItemId) {
        getChargeItems(props.itemId)
      }
    }, { immediate: true })
    function onValueChangeChargeType(e, rowId) {
      const charge = find(charges.value, item => item.rowId === rowId)
      setDefaultChargeData(charge)
      if (e.value === chargeTypeEnum.PREP_MATERIAL.key) {
        charge.isPrepMaterial = true
      } else {
        charge.isPrepMaterial = false
      }
    }
    function onValueChangeUpdateDefaultCharges() {
      context.emit('on-emit-update-default-charges', updateDefaultCharges.value)
    }
    function getCharges() {
      const result = []
      charges.value.forEach(charge => {
        const chargeType = (charge.chargeType === chargeTypeEnum.PREP_MATERIAL.key)
          ? chargeTypeEnum.PREP_MATERIAL.key
          : charge.chargeType

        const item = {
          id: charge.id,
          itemId: charge.itemId,
          costId: charge.costId,
          title: charge.costItem,
          chargeType: chargeType,
          rate: charge.rate,
          count: charge.count,
          notes: charge.notes,
        }
        result.push(item)
      })
      context.emit('on-emit-get-charges', result)
    }

    emitter.on('on-emit-set-charges', () => {
      getCharges()
    })

    return {
      updateDefaultCharges,
      charges,
      costItems,
      productItems,
      chargeTypeEnums,
      maxInt,
      inputClass,
      selectBoxAttributes,
      isIndividualTenant,
      onClickAddCharge,
      onValueChangeChargeType,
      onSelectionChangedCostData,
      onClickRemoveCharge,
      onValueChangeUpdateDefaultCharges,
    }
  },
}
</script>
<style lang="scss" scoped>
  $font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  $font-size: 1rem;
  #charge-content {
    .table {
      font-family: $font-family;
      thead {
        th {
          font-size: $font-size;
          font-weight: normal;
        }
      }
      th {
        padding: 0.72rem 0.714rem;
      }
      td {
        padding: 0.72rem 0.357rem;
      }
    }
  }
  .bg-light-blue {
    background-color: #bad7df;
  }
</style>
