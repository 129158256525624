<template>
  <div class="p-1">
    <div class="row">
      <div class="col-12 px-0  pl-2 pt-1">
        <div class="d-flex align-items-center">
          <h4 class="m-0">
            Check multiple item delivery status by tracking numbers
          </h4>
          <p-icon id="multipleInfo" name="bi-question-circle"
            color="warning" class="pt-half cursor-pointer ml-2 pb-half"
            size="24px"
          />
          <dx-util-popover :width="300" :visible="false"
            target="#multipleInfo" position="right"
            show-event="dxhoverstart"
            hide-event="dxhoverend"
          >
            Please enter/paste the tracking numbers, then press CHECK button.
          </dx-util-popover>
        </div>
      </div>
      <div class="col-12 align-items-center p-0 pl-2">
        <div class="d-flex flex-wrap align-items-center">
          <dx-util-text-area
            v-model="searchContent"
            class="col-12 col-md-8 col-lg-6 mr-1 mt-1"
            :show-clear-button="true"
            :height="80"
          />
          <dx-util-button
            type="default"
            text="Check"
            class="px-2 mt-1"
            @click="search"
          />
      </div>
      <div class="col-12 p-0">
        <dx-data-grid
          id="deliveryGrid"
          ref="deliveryGridRef"
          :data-source="filteredData"
          :height="setHeight - 210"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-export
            :enabled="true"
            :allow-export-selected-data="true"
          />
          <dx-selection mode="multiple" />
          <dx-toolbar>
            <dx-item
              location="before"
              template="statusSelectionTemplate"
            />
            <dx-item
              location="after"
              name="exportButton"
            />
          </dx-toolbar>

          <template #statusSelectionTemplate>
            <dx-util-select-box
              v-model="selectedStatus"
              :data-source="statusOptions"
              display-expr="text"
              value-expr="value"
              @value-changed="searchByFilter"
            />
          </template>
          <dx-column data-field="tracking" />
          <dx-column data-field="status" :width="160" cell-template="statusCellTemplate" />
          <template #statusCellTemplate="{data}">
            <div>
              <span :class="formatByStatus(data.value)" class="badge">
                {{ formatStatusTitle(data.value) }}
              </span>
            </div>
          </template>
          <dx-column data-field="receivedAt" data-type="datetime" />
          <dx-column data-field="receivedBy" />
          <dx-column data-field="itemCount" :width="110" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import reportsService from '@/http/requests/reports/reportService'
import { Notify } from '@/@robustshell/utils'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import deliveryStatusEnum, { getDeliveryStatusList } from '@/enums/deliveryStatus.enum.js'

export default {
  mixins: [GridBase],
  data() {
    return {
      isInfoVisible: false,
      searchContent: '',
      deliveryData: [],
      selectedStatus: deliveryStatusEnum.ALL.value,
      statusOptions: getDeliveryStatusList(),
      deliveryStatusEnum,
      filteredData: [],
    }
  },
  watch: {
    searchContent(newValue) {
      this.searchContent = this.sanitizeContent(newValue)
    },
  },
  methods: {
    async search(e) {
      if (this.searchContent === '') {
        Notify.warning('You should enter at least one item code')
        return
      }
      const searchItems = this.sanitizeContent(this.searchContent)
      const result = await reportsService.findTrackingItemsByBatch(searchItems)
      this.deliveryData = result.data
      this.searchByFilter()
      this.searchContent = ''
      this.selectedStatus = deliveryStatusEnum.ALL.value
    },
    sanitizeContent(input) {
      return input.replace(/[\W_]+/g, ',')
    },
    formatByStatus(status) {
      if (status === deliveryStatusEnum.NOT_DELIVERED.value) return 'badge-light-danger rounded'
      if (status === deliveryStatusEnum.NOT_FOUND.value) return 'badge-light-warning rounded'
      if (status === deliveryStatusEnum.DELIVERED.value) return 'badge-light-success rounded'
      return 'badge-light-primary rounded'
    },
    formatStatusTitle(status) {
      return status.replace('_', ' ')
    },
    searchByFilter() {
      if (this.selectedStatus === deliveryStatusEnum.ALL.value) {
        this.filteredData = this.deliveryData
      } else {
        this.filteredData = this.deliveryData.filter(el => el.status === this.selectedStatus)
      }
    },
  },
}
</script>
