<template>
  <div class="p-1">
    <div class="row">
      <div class="col-12 px-0  pl-2 pt-1">
        <div class="d-flex align-items-center">
          <h4 class="m-0">
            Check your item's delivery status
          </h4>
          <p-icon id="info" name="bi-question-circle"
            color="warning" class="pt-half cursor-pointer ml-2 pb-half"
            size="24px"
          />
          <dx-util-popover :width="300" :visible="false"
            target="#info" position="right"
            show-event="dxhoverstart"
            hide-event="dxhoverend"
          >
            Please enter the tracking numbers, then press CHECK button or hit ENTER key.
          </dx-util-popover>
        </div>
      </div>
      <div class="col-12 align-items-center p-0 pl-2">
        <div class="d-flex flex-wrap align-items-center">
          <dx-util-text-box
            v-model="tracking"
            class="col-12 col-md-6 col-lg-4 mr-1 mt-1"
            placeholder="Tracking Number or UPC"
            :show-clear-button="true"
            @enterKey="onTrackItem"
            @keyup="onCancel"
          />
          <dx-util-button
            type="default"
            text="Check"
            class="px-2 mt-1"
            @click="onTrackItem"
          />
          <div class="px-1 d-flex mt-1">
            <div v-if="status.creationTime" class="align-self-center">
              <i class="bi-truck text-info mx-1" style="font-size:24px" />
            </div>
            <div v-if="status.creationTime" class="align-self-center">
              <h5 class="text-success mb-0">
                Delivered on: {{ getDate(status.creationTime) }}
              </h5>
            </div>
            <div v-else-if="status.isEmpty" class="align-self-center">
              <h4 class="text-warning">
                Not delivered yet
              </h4>
            </div>
            <div v-if="status.batchName && hasPermission" class="ml-2 align-self-center">
              <h5 class="text-success mb-0">
                Batch Name: {{ status.batchName }}
              </h5>
            </div>
            <div v-if="status.scannedBy && hasPermission" class="ml-2 align-self-center">
              <h5 class="text-warning mb-0">
                Scanned By: {{ status.scannedBy }}
              </h5>
            </div>
            <div v-if="status.warehouseLocationName && hasPermission" class="ml-2 align-self-center">
              <h5 class="text-primary mb-0">
                Location: {{ status.warehouseLocationName }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-0 pl-2 pt-2">
        <div
          v-if="grid.dataSource.length > 0"
          no-body
          :class="skin !== 'dark' ? 'light-white' : '' "
          class="shadow-none"
          >
          <div class="d-flex align-items-center pb-1  pt-1 mt-1">
            <h4>
              Package Content
            </h4>
            <div id="contentInfo" class="mx-1" @mouseenter="toggleContentInfo" @mouseleave="toggleContentInfo">
            <i class="bi bi-question-circle text-warning" style="font-size:20px;" />
          </div>
          <dx-util-popover
            :width="300"
            :visible="isContentInfoVisible"
            target="#contentInfo"
            position="left"
          >
            <small>
              The items listed below are generated and matched with your uploaded items at best effort manner. This table will be more accurate as you insert more information in your csv file uploads, such as tracking number, order number, UPC codes, etc..
            </small>
          </dx-util-popover>
          </div>
          <dx-data-grid
            id="packageContent"
            ref="packageContentGrid"
            :data-source="grid.dataSource"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
          >
            <dx-column data-field="itemAction" caption="Action" alignment="center" :width="90" />
            <dx-column data-field="imageLink" cell-template="imageCellTemplate" caption="Image" alignment="center" :allow-sorting="false" :width="70" />
            <template #imageCellTemplate="{data}">
              <div>
              <img class="rounded" :src="data.value || defaultImageUrl" style="height:4rem">
              {{ data.value }}
            </div>
            </template>
            <dx-column data-field="title" caption="Title" cell-template="titleCellTemplate" width="300" />
            <template #titleCellTemplate="{data}">
              <div style="width:300px; overflow-wrap:break-word; white-space:normal; height:100%">
                {{ data.value }}
              </div>
            </template>
            <dx-column
              data-field="tracking"
              alignment="left"
              :width="160"
              cell-template="trackingDetialsTemplate"
              :allow-filtering="false"
            />
            <template #trackingDetialsTemplate="{ data }">
              <div v-if="data.value && data.value !== '-'">
                {{ data.value }}
              </div>
              <div v-else>
                <dx-util-button
                  text="Tracking Details"
                  type="success"
                  styling-mode="outlined"
                  :element-attr="btnElementAttr"
                  @click="showTrackingDetails(data.data)"
                />
              </div>
            </template>

            <dx-column data-field="palletId" caption="Pallet Id" />
            <dx-column data-field="orderNumber" />
            <dx-column data-field="upcCode" caption="UPC" :width="120" />
            <dx-column data-field="asin" caption="ASIN" alignment="left" :width="120" cell-template="asinCellTemplate" />
            <template #asinCellTemplate="{data}">
              <a target="blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
                {{ data.value }}
              </a>
            </template>
            <!-- <dx-column data-field="quantity" caption="QTY" alignment="right" :width="80" /> -->
            <dx-column data-field="uploadedQty" caption="Uploaded" />
            <dx-column data-field="sortedQty" caption="Sorted" />
            <dx-column data-field="clientShipmentItemId" caption="Reference" alignment="right" :width="90" />
            <dx-column data-field="shippedQty" caption="Shipped" />
            <dx-column data-field="accountNo" />
            <dx-column data-field="suiteNo" />
            <dx-column data-field="companyName" />
            <dx-column data-field="storeName" />
            <dx-column data-field="unloadedAt" caption="Delivered At" data-type="date" cell-template="deliverCellTemplate" />
            <dx-column data-field="sortedAt" data-type="date" cell-template="deliverCellTemplate" />
            <template #deliverCellTemplate="{data}">
              <div class="text-success">
                {{ getDate(data.value) }}
              </div>
            </template>
          </dx-data-grid>
        </div>
      </div>
    </div>
    <dx-util-popup
      ref="sortedItemsPopupRef"
      :drag-enabled="true"
      :show-close-button="true"
      :show-title="true"
      :width="1000"
      :height="1000"
      title="Sorted Items"
      content-template="popup-content"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <div class="d-flex flex-column">
            <dx-data-grid
            id="sortedItemspackageContent"
            ref="sortedItemspackageContentGrid"
            :data-source="sortedItemsDetails.dataSource"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
          >
            <dx-column data-field="tracking" />
            <dx-column data-field="orderNumber" />
            <dx-column data-field="palletId" caption="Pallet Id" />
            <dx-column data-field="sortedQty" caption="Sorted" width="80" />
            <!-- <dx-column data-field="unloadedAt" caption="Delivered At" data-type="date" width="110" cell-template="deliverCellTemplate" /> -->
            <dx-column data-field="sortedAt" data-type="date" width="110" cell-template="deliverCellTemplate" />
            <template #deliverCellTemplate="{data}">
              <div class="text-success">
                {{ getDate(data.value) }}
              </div>
            </template>
          </dx-data-grid>
          </div>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
  </div>
</template>
<script>

import { VBPopover } from 'bootstrap-vue'
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import Pager from '@core/dev-extreme/utils/pager'
import { formatDate } from '@core/utils/filter'
import { isValid } from 'gtin'
import packageScanService from '@/http/requests/inbound/packageScanService'
import sortItemService from '@/http/requests/inbound/sort-item.service'
import ItemSortFilter from '@/http/models/search-filters/ItemSortFilter'
import CatalogItem from '@/libs/app/catalog-item'
import useAppConfig from '@core/app-config/useAppConfig'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  directives: {
    'b-popover': VBPopover,
  },
  mixins: [GridBase],
  data() {
    return {
      tracking: '',
      status: { isEmpty: false },
      deliveryDate: '',
      grid: {
        dataSource: [],
      },
      sortedItemsDetails: {
        dataSource: [],
      },
      btnElementAttr: {
        class: 'btn-block',
      },
      isContentInfoVisible: false,
      defaultImageUrl,
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.packageContentGrid.instance
      return grid
    },
    sortedItemsDetailsGrid() {
      const grid = this.$refs.sortedItemsDetailsGrid.instance
      return grid
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  setup() {
    const { skin } = useAppConfig()

    return { skin }
  },
  methods: {
    getImageLink(item) {
      return item ? item.images[0].images[0].link : '#'
    },
    onTrackItem() {
      this.tracking = this.tracking.trim()
      if (this.tracking === '') {
        return
      }
      this.status = { isEmpty: false }
      this.grid.dataSource = []
      try {
        if (isValid(this.tracking)) {
          this.fetchPackageContent(true)
        } else {
          this.fetchByTracking()
        }
      } catch (error) {
        this.fetchByTracking()
      }
    },
    async showTrackingDetails(item) {
      const result = await sortItemService.getByClientShipmentItemId(item.clientShipmentItemId)
      const { data } = result
      this.sortedItemsDetails.dataSource.length = 0
      this.sortedItemsDetails.dataSource = [...data.body]
      const popup = this.$refs.sortedItemsPopupRef.instance
      popup.show()
    },
    fetchByTracking() {
      packageScanService.fetchByTracking(this.tracking).then(result => {
        const { data } = result
        this.status = data
        if (this.status && this.status.id) {
          this.fetchPackageContent(false)
        } else {
          this.status = { isEmpty: true }
        }
      })
    },
    fetchPackageContent(isUpc) {
      ItemSortFilter.setDefaultFilters()
      if (isUpc) {
        ItemSortFilter.q = this.tracking
      } else {
        ItemSortFilter.packageScanId = this.status.id
      }
      ItemSortFilter.status = 'ALL'
      const filters = ItemSortFilter.getFilters()
      const pager = new Pager({})
      return sortItemService.trackItemsByQuery(filters, pager.staticPageable)
        .then(response => {
          const data = response.data.body.content
          const dataSource = []
          data.forEach(dataItem => {
            const item = { ...dataItem }
            if (item.catalogItem) {
              const catalogItemIns = new CatalogItem()
              catalogItemIns.catalogItem = item.catalogItem
              item.imageLink = catalogItemIns.getImageLink()
            }
            dataSource.push(item)
          })
          this.grid.dataSource = [...dataSource]
        })
    },
    getDate(date) {
      return formatDate(date)
    },
    onCancel() {
      this.tracking = ''
      this.status.isEmpty = false
    },
    toggleContentInfo() {
      this.isContentInfoVisible = !this.isContentInfoVisible
    },
  },
}
</script>

<style scoped>
.light-white {
  background-color: #fafafa;
}
</style>
