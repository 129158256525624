<template>
  <div>
    <shipment-items :batch-id="batchId" :batch-name="batchName" :component-id="shipmentItemsComponentId" />
    <dx-data-grid
      :data-source="dataSource"
      :show-row-lines="true"
      :show-borders="true"
      :column-auto-width="true"
    >
      <dx-column data-field="batchName" cell-template="batch-name-template" />
      <dx-column data-field="itemAction" />
      <dx-column data-field="shipDate" data-type="datetime" />
      <dx-column data-field="count" />
      <template #batch-name-template="{ data: detail }">
        <a v-if="detail.data.batch" href="#" class="font-weight-bold d-block text-nowrap" @click="onBatchNameLinkClick(detail)">
          <span class="text-capitalize badge badge-light-info badge-pill">
            {{ detail.data.batchName }}
            <feather-icon class="ml-1" icon="ExternalLinkIcon" size="18" />
          </span>
        </a>
        <div v-else>
          {{ detail.data.batchName }}
        </div>
      </template>
    </dx-data-grid>
  </div>
</template>
<script>
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import { v4 as uuidv4 } from 'uuid'
import ShipmentItems from '~/views/apps/outbound/shipment-batches/ShipmentItems.vue'

export default {
  components: {
    'shipment-items': ShipmentItems,
  },
  mixins: [GridBase],
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const dataItems = this.detailData.data
    return {
      batchId: 0,
      batchName: '',
      dataSource: dataItems.shipmentItemsAndBatch,
      shipmentItemsComponentId: '',
    }
  },
  methods: {
    onBatchNameLinkClick(e) {
      this.batchId = e.data.batchId
      this.batchName = e.value
      // this.$refs['shipped-items-modal'].show()
      this.shipmentItemsComponentId = uuidv4()
    },
  },
}
</script>
<style>
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 5px 5px !important;
}
</style>
