<template>
  <div class="row">
    <div class="col-12">
      <dx-data-grid
        id="inventoryGridId"
        ref="inventorySnapshotGrid"
        key-expr="productId"
        :data-source="grid.dataSource"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="false"
        :hover-state-enabled="true"
        :customize-columns="customizeColumns"
        @initialized="onInitialized"
        @toolbar-preparing="onToolbarPreparing($event)"
        @option-changed="onOptionChanged"
        @content-ready="onContentReady"
      >
          <dx-column-chooser
            :enabled="true"
            mode="select"
            title="Column Chooser"
          />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <dx-paging :page-size="100" />
          <dx-pager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
          />
        <dx-column data-field="title" caption="TITLE" cell-template="cellTITLE" />
           <template #cellTITLE="{ data }">
           <div class="product-title">
              {{ data.value }}
           </div>
        </template>
        <dx-column data-field="storeName" caption="Store" :width="130" />
        <dx-column data-field="upc" caption="UPC" :width="130" />
        <dx-column data-field="asin" caption="ASIN" cell-template="cellASIN" :width="130" />
        <template #cellASIN="{ data }">
          <div class="text-default text-nowrap">
            <a :href="`https://www.amazon.com/gp/product/${data.value}`" target="_blank">
                {{ data.value }}
            </a>
          </div>
        </template>
        <dx-column data-field="msku" caption="MSKU" :width="'7%'" />
        <dx-column data-field="fnsku" caption="FNSKU" :width="'7%'" />
        <dx-column data-field="fba" caption="FBA" :width="'5%'" />
        <dx-column data-field="fbm" caption="FBM" :width="'5%'" />
        <dx-column data-field="wfs" caption="WFS" :width="'5%'" />
        <dx-column data-field="storageCount" :width="'5%'" />
        <dx-column data-field="forwarding" :width="'5%'" />
        <dx-column data-field="returnCount" :width="'5%'" />
        <dx-column data-field="dropship" :width="'5%'" />
        <dx-column data-field="b2b" caption="B2B" :width="'5%'" />
        <dx-column data-field="problem" :width="'5%'" />
        <dx-column data-field="shipped" :width="'5%'" />
        <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1 w-25">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="getInventorySnapshotDataByAccount"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="getInventorySnapshotData"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="getInventorySnapshotData"
                />
              </div>
              <div class="mr-1 w-35">
                <dx-util-check-box
                  v-model="includeAllShipped"
                  text="Include All Shipped Items"
                  @value-changed="getInventorySnapshotData"
                />
              </div>
              <div class="mr-1 w-35">
                <p-icon id="inventoryTab" name="bi-question-circle"
                  color="warning" class="pt-half cursor-pointer"
                  size="24px"
                />
                <dx-util-popover :width="300" :visible="false"
                  target="#inventoryTab" position="right"
                  show-event="dxhoverstart"
                  hide-event="dxhoverend"
                >
                  You can use the inventory and store filters to narrow down the results
                </dx-util-popover>
              </div>
            </div>
        </template>
        <dx-column
          v-if="hasPermission"
          data-field="id"
          caption="Actions"
          alignment="center"
          cell-template="itemActions"
        />
        <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="default"
                icon="map"
                hint="Items Location"
                @click="onClickGetItemLocation(data)"
              />
            </div>
        </template>
      </dx-data-grid>
    </div>
    <!-- Location Detail -->
    <dx-util-popup
      :visible="popupLocationVisible"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="true"
      title="Item Location"
      @showing="popupLocationShowing"
      @hidden="popupLocationHidden"
    >
      <dx-util-position at="center" my="center" />
      <item-locations ref="itemLocationRefs" :filters="locationFilter" :show-action="false" />
    </dx-util-popup>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import inventoryService from '@/http/requests/inventory/inventoryService'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import { Notify } from '@robustshell/utils/index'
import Filters from '@robustshell/mixins/filters'
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import Pageable from '@robustshell/components/Grid/pageable'
import ItemLocations from '@/views/apps/inventory/view/ItemLocations'

export default {
  components: {
    ItemLocations,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      isResult: false,
      includeAllShipped: false,
      currentUser: null,
      resultData: [],
      grid: {
        options: {
          allMode: 'allPages',
          checkBoxesMode: 'always',
        },
        dataSource: [],
        currentFilter: '',
      },
      searchText: '',
      accountNo: '',
      pageSizes: [30, 50, 100],
      pageable: new Pageable({ pageSize: 1000 }),
      locationFilter: {},
      popupLocationVisible: false,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const dataGridIns = this.$refs.inventorySnapshotGrid.instance
      return dataGridIns
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('userData'))
    this.getWarehouses()
    this.getStores()
    this.getInventorySnapshotData()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Inventory',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Inventory',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadInventory()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedInventory()
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
    },
    async onDownloadInventory(e) {
      InventorySearchFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
      const filters = InventorySearchFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await inventoryService.inventorySnapshot(filters, pageableQuery)
      const data = response.data.content
      this.onDownloadExportData(data)
    },
    onDownloadSelectedInventory() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Inventory ${fileDate}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 100 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'MSKU', key: 'msku', width: 30 },
        { header: 'FNSKU', key: 'fnsku', width: 14 },
        { header: 'In-Prep', key: 'fba', width: 20 },
        { header: 'FBM', key: 'fbm', width: 10 },
        { header: 'WFS', key: 'wfs', width: 10 },
        { header: 'Forwarding ', key: 'forwarding', width: 18 },
        { header: 'Return', key: 'returnCount', width: 12 },
        { header: 'Dropship', key: 'dropship', width: 9 },
        { header: 'B2B', key: 'b2b', width: 9 },
        { header: 'Problem', key: 'problem', width: 9 },
        { header: 'Shipped', key: 'shipped', width: 9 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:L1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.upc,
          item.asin,
          item.msku,
          item.fnsku,
          item.fba,
          item.fbm,
          item.wfs,
          item.forwarding,
          item.returnCount,
          item.dropship,
          item.b2b,
          item.problem,
          item.shipped,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Inventory ${fileDate}.xlsx`,
        )
      })
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.searchText = ''
        this.searchText = e.value
      }
    },
    getInventorySnapshotDataByAccount(e) {
      if (e.event.keyCode !== 13) return
      this.accountNo = e.event.target.value
      this.selectedStore = 0
      this.getInventorySnapshotData()
      this.getStores(this.accountNo)
    },
    getInventorySnapshotData() {
      InventorySearchFilter.accountNo = this.accountNo
      InventorySearchFilter.companyId = this.currentUser.companyId
      InventorySearchFilter.warehouseId = this.selectedWarehouse
      InventorySearchFilter.storeId = this.selectedStore
      InventorySearchFilter.q = this.searchText
      InventorySearchFilter.includeAllShipped = this.includeAllShipped
      const filters = InventorySearchFilter.getFilters()

      inventoryService
        .inventorySnapshot(filters, this.pageable.toCreateQueryString())
        .then(result => {
          const { data } = result
          this.grid.dataSource.splice(0, this.grid.dataSource.length)
          this.grid.dataSource.push(...data.content)
          this.setPagination(data)
        })
    },
    setPagination(context) {
      this.pageable.setContext(context)
    },
    customizeColumns(columns) {
      columns.forEach(column => {
        // eslint-disable-next-line no-param-reassign
        column.cssClass = 'text-center dx-column-vertical-middle'
      })
    },
    onClickGetItemLocation(e) {
      this.popupLocationVisible = true
      const item = { ...e.row.data }
      Object.assign(this.locationFilter, {
        storeId: item.storeId,
        warehouseId: item.warehouseId,
        companyId: item.companyId,
        productId: item.productId,
        itemAction: 'FBA',
      })
    },
    popupLocationHidden(e) {
      this.popupLocationVisible = false
    },
    popupLocationShowing(e) {
      this.$refs.itemLocationRefs.onShownItemsLocation()
    },
  },
}
</script>

<style scoped>
.product-title {
  min-width: 300px;
  max-width: 400px;
  height: 100%;
  overflow-wrap:break-word;
  white-space:normal;
  text-align: left;
}
</style>
