<template>
  <div>
    <dx-util-popup
      ref="shippedItemsRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      :title="shippedItemsTitle"
      :full-screen="true"
      content-template="popup-content"
      @hidden="onHiddenShippedItems"
      @shown="onShownShippedItems"
      @showing="onShowingShippedItems"
    >
    <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <div>
            <div class="row">
              <div class="col-md-4 text-left">
                <div class="d-flex flex-row">
                  <div class="d-flex flex-column mr-1">
                    <div class="text-success text-nowrap">
                      Total Cost: {{ totalBatchCost | formatCurrency }}
                    </div>
                    <div class="text-success text-nowrap">
                      Total Sale: {{ totalBatchSalePrice | formatCurrency }}
                    </div>
                  </div>
                  <div class="text-success text-nowrap">
                    Cost Ratio: {{ batchSellRatio | formatPercentage }}
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="text-right">
                  <dx-progress-bar
                    :min="0"
                    :max="totalQuantity"
                    :value="totalDelivered"
                    :status-format="statusFormat"
                    :element-attr="{ class: 'custom-progress-bar' }"
                    />
                </div>
              </div>
            </div>
              <dx-data-grid
                id="mainGridId"
                ref="mainGridRef"
                :data-source="grid.dataSource"
                :height="setHeight"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :show-borders="true"
                column-resizing-mode="widget"
                :row-alternation-enabled="true"
                @content-ready="onContentReady"
              >
                <dx-remote-operations :paging="false" :filtering="false" :sorting="false" :summary="false" />
                <dx-paging :page-size="pagerOptions.pageSize" />
                <dx-pager
                  :allowed-page-sizes="pagerOptions.allowedPageSizes"
                  :show-info="pagerOptions.showInfo"
                  :show-navigation-buttons="pagerOptions.showNavigationButtons"
                  :show-page-size-selector="pagerOptions.showPageSizeSelector"
                  :visible="pagerOptions.visible"
                />
                <dx-filter-row :visible="true" />
                <dx-column data-field="title" caption="Title" cell-template="productTitleTemplate" />
                <dx-column data-field="upc" caption="UPC" :width="120" cell-template="upcCellTemplate" />
                <template #upcCellTemplate="{data}">
                  <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
                    {{ data.value }}
                  </a>
                </template>
                <dx-column data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" />
                <template #asinCellTemplate="{data}">
                  <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
                    {{ data.value }}
                  </a>
                </template>
                <dx-column data-field="fnsku" caption="FNSKU" />
                <dx-column data-field="msku" caption="MSKU" />
                <dx-column data-field="expirationDate" caption="Exp" data-type="date" />
                <dx-column data-field="inventorySku" :visible="!batchItems" />
                <dx-column data-field="orderNumber" caption="Order #" :visible="!batchItems" />
                <dx-column data-field="tracking" caption="Tracking" :visible="!batchItems" />
                <dx-column data-field="palletId" :visible="!batchItems" />
                <dx-column data-field="expiration" data-type="date" :visible="!batchItems" />
                <dx-column data-field="quantity" caption="Shipped QTY" />
                <dx-column data-field="deliveredQty" caption="Delivered QTY" cell-template="deliveredQtyTemplate" alignment="center" width="150" />
                <template #productTitleTemplate="{ data }">
                  <div class="product-title">
                    {{ data.value }}
                </div>
                </template>
                <template #deliveredQtyTemplate="{ data }">
                  <div>
                    <span :class="getItemsDeliveredStatus(data)" class="d-block badge">
                      {{ data.value }}
                    </span>
                  </div>
                </template>
                <dx-column data-field="shipTime" caption="Ship Date" data-type="date" :visible="!batchItems" />
                <dx-column data-field="creationTime" caption="Ship Date" data-type="date" :visible="batchItems" />
                <dx-column v-if="!batchItems" data-field="id"
                  caption="Actions" alignment="center"
                  cell-template="itemActions" :width="80"
                  :allow-filtering="false"
                />
                <template #itemActions="{data}">
                  <div>
                    <dx-util-button
                      ref="btnAddCharge"
                      type="success" icon="bi bi-coin"
                      hint="Charge Details"
                      @click="onClickAddCharges(data)"
                    />
                  </div>
                </template>
                <DxToolbar>
                  <DxItem
                    :visible="showPageProperties"
                    location="before"
                    template="propertyTemplate"
                  />
                  <DxItem
                    :visible="batchItems"
                    location="after"
                    template="downloadButtonTemplate"
                  />
                </DxToolbar>
                <template #propertyTemplate>
                  <div class="d-flex flex-row align-items-center">
                    <div class="d-flex flex-row align-items-center px-1">
                      <h5 class="text-success mr-2">
                        Company Name: {{ $route.query.companyName }}
                      </h5>
                      <h5 class="text-success mr-2">
                        Store Name: {{ $route.query.storeName }}
                      </h5>
                      <h5 class="text-success">
                        Batch Name: {{ $route.query.batchName }}
                      </h5>
                    </div>
                  </div>
                </template>
                <template #downloadButtonTemplate>
                  <div>
                  <DxButton
                    icon="download"
                    hint="Download"
                    type="normal"
                    @click="onDownloadExportData"
                  />
                </div>
                </template>
              </dx-data-grid>
          </div>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
      <dx-util-popup
        id="popupCharges"
        ref="popupChargesRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :height="720"
        content-template="popup-content"
        title="Shipment Item Charges"
        @showing="onShowingChargesPopup"
        @hiding="onHidingChargesPopup"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
          <charges v-if="charges.isCreated" :item-id="charges.itemId" :read-only="!hasPermission" :products="selectedProduct" :load-with-item-id="true" item-type="product" @on-emit-get-charges="onEmitGetCharges" />
        </template>
        <dx-util-toolbar-item v-if="hasPermission" :visible="chargeUpdateAllowed" widget="dxButton" toolbar="bottom" location="after" :options="saveChargesOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closeChargesOptions" />
      </dx-util-popup>
  </div>
</template>

<script>
import { emitter } from '@/libs/bus'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import Charges from '@components/charge/Charges.vue'
import { DxProgressBar } from 'devextreme-vue/progress-bar'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import { formatDate } from '@core/utils/filter'

const statusFormat = ratio => `Delivered: ${(ratio * 100).toFixed(2)}%`

export default {
  components: {
    Charges,
    DxProgressBar,
  },
  mixins: [GridBase],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    batchId: {
      type: Number,
      required: false,
    },
    batchName: {
      type: String,
      required: false,
      default: () => '',
    },
    batchItems: {
      type: Boolean,
      required: false,
    },
    componentId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    shippedItemsTitle: '',
    filters: {
      selectedCompanyType: -1,
      selectedStatus: '',
      accountNo: '',
      query: '',
      filterOn: [],
    },
    grid: {
      options: {
        allMode: 'allPages',
        checkBoxesMode: 'always',
      },
      dataSource: [],
      currentFilter: '',
      selectedRow: {
        id: 0,
      },
      selectedRows: [],
    },
    selectedProduct: [],
    charges: {
      itemId: null,
      isCreated: false,
    },
    chargeUpdateAllowed: false,
    showPageProperties: false,
    totalBatchCost: 0,
    totalBatchSalePrice: 0,
    batchSellRatio: 0,
    totalQuantity: 0,
    totalDelivered: 0,
    statusFormat,
  }),
  computed: {
    shippedItemsPopupIns() {
      return this.$refs.shippedItemsRef.instance
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    saveChargesOptions() {
      return {
        text: 'Save Charges',
        type: 'success',
        onClick: () => {
          emitter.emit('on-emit-set-charges')
        },
      }
    },
    closeChargesOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.popupChargesRef.instance
          popup.hide()
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (this.$route.query.batchId) {
          this.showPageProperties = true
        }
        if (val && val !== oldVal) {
          this.createShippedItems()
        }
        if (this.batchItems) {
          this.getBatchItems()
        } else {
          this.getBatchInventoryItems()
        }
      },
    },
  },
  methods: {
    createShippedItems() {
      this.shippedItemsPopupIns.show()
    },
    async onShowingShippedItems() {
      await this.shipmentBatchStats()
    },
    onShownShippedItems() {
      this.shippedItemsTitle = `${this.batchName}: Shipped Items`
    },
    onHiddenShippedItems() {
      this.shippedItemsTitle = ''
      this.totalBatchCost = 0
      this.totalBatchSalePrice = 0
      this.batchSellRatio = 0
      this.totalQuantity = 0
      this.totalDelivered = 0
    },
    isActiveChargeAction(e) {
      return e.row.data.invoiceId && e.row.data.invoiceId !== null
    },
    onEmitGetCharges(payload) {
      const charges = payload
      itemChargeService.create(this.itemId, charges).then(() => {
        const popup = this.$refs.popupChargesRef.instance
        popup.hide()
      })
    },
    async shipmentBatchStats() {
      const result = await outboundShippingService.shipmentBatchStats(this.batchId)
      this.totalBatchCost = result.data.body.totalCost || 0
      this.totalBatchSalePrice = result.data.body.totalSalePrice || 0
      this.totalQuantity = result.data.body.totalQuantity || 0
      this.totalDelivered = result.data.body.totalDelivered || 0
      this.batchSellRatio = (this.totalBatchCost / this.totalBatchSalePrice) || 0
    },
    onClickAddCharges(e) {
      this.selectedProduct.length = 0
      this.chargeUpdateAllowed = !this.isActiveChargeAction(e)
      const { data } = e.row
      this.itemId = data.id
      this.selectedProduct.push({
        id: data.id,
        text: `${data.asin}-${data.title}`,
      })
      const popup = this.$refs.popupChargesRef.instance
      this.charges.itemId = data.id
      popup.show()
    },
    onShowingChargesPopup() {
      this.charges.isCreated = true
    },
    onHidingChargesPopup() {
      this.charges = {
        itemId: null,
        isCreated: false,
      }
    },
    getItemsDeliveredStatus(data) {
      if (data.data.deliveredQty === 0) return 'badge-danger'
      if (data.data.deliveredQty === data.data.quantity) return 'badge-success'
      if (data.data.deliveredQty < data.data.quantity) return 'badge-warning'
      return 'badge-primary'
    },
    setPagination(context) {
      this.pageable.setContext(context)
    },
    getBatchInventoryItems() {
      this.grid.dataSource.length = 0
      const batchId = this.showPageProperties ? this.$route.query.batchId : this.batchId
      outboundShippingService
        .fetchAllItemsByBatchId(batchId)
        .then(result => {
          this.grid.dataSource = [...result.data.content]
        })
    },
    getBatchItems() {
      const batchId = this.showPageProperties ? this.$route.query.batchId : this.batchId
      this.grid.dataSource.length = 0
      const filters = {
        companyId: 0,
        accountNo: '',
        storeId: 0,
        warehouseId: 0,
        beginDate: null,
        endDate: null,
        tenantType: 'SERVING',
        batchId: batchId,
        status: 'ALL',
        batchStatus: 'all',
      }
      fbaShipmentService.getBatchParentItemsByQuery(filters).then(result => {
        this.grid.dataSource = [...result.data]
      })
    },
    onChangeData() {
      this.getBatchInventoryItems(this.filters.params)
    },
    onDownloadExportData() {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`${this.batchName}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 100 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'MSKU', key: 'msku', width: 30 },
        { header: 'FNSKU', key: 'fnsku', width: 14 },
        { header: 'Expiration', key: 'expirationDate', width: 14 },
        { header: 'QTY', key: 'quantity', width: 12 },
        { header: 'Delivered QTY', key: 'deliveredQty', width: 12 },
        { header: 'Ship Date', key: 'creationTime', width: 12 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:H1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      this.grid.dataSource.forEach(item => {
        worksheet.addRow([
          item.title,
          item.upc,
          item.asin,
          item.msku,
          item.fnsku,
          formatDate(item.expirationDate),
          item.quantity,
          item.deliveredQty,
          formatDate(item.creationTime),
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${this.batchName} ${fileDate}.xlsx`,
        )
      })
    },
  },
}
</script>
<style>
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important;
}
.dx-datagrid-header-panel {
  padding-top: 10px;
}

.dx-datagrid-search-panel {
  margin: 0 0 !important ;
}
.product-title {
  /* min-width: 300px;
  max-width: 400px;
  height: 100%; */
  overflow-wrap:break-word;
  white-space:normal;
  text-align: left;
}

.custom-progress-bar .dx-progressbar-range {
    height: 20px;
    background-color: rgb(54, 170, 54);
}

.custom-progress-bar  .dx-progressbar-container {
  height: 22px;
}

.dx-progressbar-status {
  font-size: 16px;
  position: absolute;
  top: 0px;
  left: 22px;
}
</style>
